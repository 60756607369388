import React from 'react'
import Modal from '../Modal/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import PricingTable from './PricingTable'
import ConversionTracker from './ConversionTracker'

const DonationModal = ({openAboutModal, setOpenAboutModal}) => {
    return (
        <div>

            <ConversionTracker />

              <Modal open={openAboutModal}>
                    <div className="d-flex justify-content-center">
                        <h4 style={{position: 'absolute', right: '1em'}}>
                            <button className="close" onClick={() => setOpenAboutModal(false)}><FontAwesomeIcon icon={faCircleXmark} /></button>
                        </h4>
                    </div>

                    <div className="modal text-gray-800 leading-7">
                        
                        <div id="modalHeader">
                            <h2>Support Moment of Mindfulness</h2>
                        </div>
                        <hr/>
                        <div id="donationContent" className="ModalContent">
                            Thank you for your consideration in supporting us.  Your support contributes toward our hosting and costs to run our site.
                        </div>
                        <div id="aboutContent" className="ModalContent">
                            <PricingTable />
                        </div>
                    </div>                        
                </Modal>
        </div>
    )
}

export default DonationModal
