import React, { Component } from 'react'
import GetMessage from './GetMessage';
import Timer from './Timer';
import Footer from '../Layouts/Footer';
import Div100vh from 'react-div-100vh';

class Homepage extends Component {

  constructor(props) {
    super(props);
      this.state = {
        bgColor: [
          '#FDF8E2', 
          '#FFDDD3',
          '#F3BFB3',
          '#5EA9BE',
          '#9ACDE0',
          '#CBE1EF',
        ],
        selectedColor: '',
        DataisLoaded: false,
      };

      this.UpdateState = this.UpdateState.bind(this);

    }
      
    componentDidMount() {
      this._getRandomColor()
    }
      
    _getRandomColor(){
      var item = this.state.bgColor[Math.floor(Math.random()*this.state.bgColor.length)];
      this.setState({
        selectedColor: item,
      })
    }

    UpdateState(x) {
      this.setState({
        DataisLoaded: x,        
      })
    }

  render () {

    return (
      
      <Div100vh>

        <div className="Layout" style={{backgroundColor: this.state.selectedColor}}>
          <div className="Header">
            <Timer StartTimer={this.state.DataisLoaded}/>
          </div>
          <div className="Main">
            <h1><center><GetMessage DataisLoaded={this.state.DataisLoaded} UpdateLoadedState={this.UpdateState} /></center></h1>
          </div>
          <div className="Footer">
            <Footer/>
          </div>
        </div>

      </Div100vh>
      
    ) 

  }

}

export default Homepage;