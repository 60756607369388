import * as React from 'react';

function PricingPage() {
  // Paste the stripe-pricing-table snippet in your React component
  return (
    <stripe-pricing-table
      pricing-table-id="prctbl_1Om4N7JyXzDDhWF2qaXoiVXS"
      publishable-key="pk_live_51N1MGKJyXzDDhWF2d2HWxLEiaRRRLTr3EuCURptWnCLcOvtSps3stfjbGfMgUKRzRqhfNgnylz4QrLqcW4Brdejo008aC1G3K6"
    >
    </stripe-pricing-table>
  );
}

export default PricingPage;