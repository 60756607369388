import React, { useState } from "react";
import AboutModal from "./AboutModal";
import Logo from '../../Assets/Logo35px.svg'


const About = () => {
  const [openAboutModal, setOpenAboutModal] = useState(false);

  return (
    <>
      {" "}
      {openAboutModal && openAboutModal && (
        <AboutModal
          openAboutModal={openAboutModal}
          setOpenAboutModal={() => setOpenAboutModal(false)}
        />
      )}
      <button
        onClick={() => setOpenAboutModal(true)}
        className="ImageButton"
        title="About"
        >
         {/* <FontAwesomeIcon icon={faQuestion} className="FooterIcon"/> */}
         <img src={Logo} alt="Logo" />
      </button>
    </>
  );
};
export default About;
