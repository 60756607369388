import Modal from '../Modal/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import Logo from '../../Assets/Logo35px.svg'

const AboutModal = ({openAboutModal, setOpenAboutModal}) => {
    return (
        <div>
              <Modal open={openAboutModal}>
                    <div className="d-flex justify-content-center">
                        <h4 style={{position: 'absolute', right: '1em'}}>
                            <button className="close" onClick={() => setOpenAboutModal(false)}><FontAwesomeIcon icon={faCircleXmark} /></button>
                        </h4>
                    </div>

                    <div className="modal text-gray-800 leading-7">
                        <div id="modalHeader" className="ModalHeader">
                            <img src={Logo} alt="Logo" /> &nbsp;
                            <h2>About Moment of Mindfulness</h2>
                        </div>
                        <hr/>
                        <div id="aboutContent" className="ModalContent">
                            We built this to help people, ground them in some truth, to calm the mind and use a short period of time to reflect and escape from the busy world we live in. <br/>
                            If this has been helpful for you and you are able to please consider supporting me to cover the costs of hosting.<br/>
                        </div>
                        <div id="modalHeader">
                            <h2>Suggested Usage</h2>
                        </div>
                        <hr/>
                        <div id="aboutContent" className="ModalContent">
                            A suggestion on how to use this: Read the message, pause and think; how does the message make you feel, what you are grateful for, some actions that you can do keep improving, do this for 60 seconds.  
                            <br/>An example of a message might be "I am grateful for the positive people in my life"<br/>
                            <ul className=" text-gray-800 list-disc list-inside dark:text-gray-800">
                                <li>Who are those positive people?</li>
                                <li>For each of those people what are you grateful for?</li>
                                <li>What can you tell them that you are grateful them for?</li>
                                <li>What can you do to continue to surround yourself with positive influences</li>
                            </ul>
                            These can be read outloud or in your mind and meditated on as many times as necessary throughout the day.  The message changes daily, the same message will not appear twice within the same year. <br/><br/>
                        </div>

                        <div id="modalHeader">
                            <h2>Copyright</h2>
                        </div>
                        <hr/>
                        <div id="aboutContent" className="ModalContent">
                            Copyright 2024 True Technologies Limited.
                        </div>
                        <div id="modalHeader">
                            <h2>Subscriptions and Support</h2>
                        </div>
                        <hr/>
                        <div id="aboutContent" className="ModalContent">
                            True Technologies partners with Stripe for payment processing. <br/> For any support query please send us a message.
                        </div>
                    </div>                        
                </Modal>
        </div>
    )
}

export default AboutModal
