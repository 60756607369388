import React, { useState } from "react";
import DonationModal from "./DonationModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

const Donations = () => {
  const [openAboutModal, setOpenAboutModal] = useState(false);

  return (
    <>
      {" "}
      {openAboutModal && openAboutModal && (
        <DonationModal
          openAboutModal={openAboutModal}
          setOpenAboutModal={() => setOpenAboutModal(false)}
        />
      )}
      <button
        onClick={() => setOpenAboutModal(true)}
        className="TextButton hover:shadow-lg focus:outline-none px-6 py-3 mb-1 mr-1 text-sm text-white uppercase transition-all duration-150 ease-linear bg-blue-500 rounded shadow outline-none"
        title="Support Us"
        >
          <FontAwesomeIcon icon={faHeart} />&nbsp; Support Us
      </button>
    </>
  );
};
export default Donations;