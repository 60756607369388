import '../../Components/CSS/Fader.css'

export default function TimerCompletion() {

return (
    <div className="TimerCompletionPosition">
        <div className='animated fadeIn'>
            <div className='TimerCompletionContainer'>
                <h3>Congratulations on completing 60 seconds. 😊</h3>
            </div>
        </div>
    </div>
)

}