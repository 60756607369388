import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import TimerCompletion from './TimerCompletion';

export default function Timer( {StartTimer} ) {
  

  const [timer, setTimer] = React.useState(0);
  const MaxTime = 60;

  const id =React.useRef(null);
  const clear=()=>{
    window.clearInterval(id.current)
  }

  React.useEffect(()=>{
      id.current=window.setInterval(()=>{
        setTimer((time)=>time+1)
      },1000)
      return ()=>clear();
  },[])

  React.useEffect(()=>{
    if(timer===MaxTime){
      clear()
    }
  },[timer])

  

  if(timer===MaxTime) {
    return(
      <div className="Timer">
        <TimerCompletion/>
        <FontAwesomeIcon icon={faCheck} className="TimerIcon"/>
      </div>
    )
  } else {
    return (
      <div className="Timer">
        {timer}
      </div>
    );
  }  
}