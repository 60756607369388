import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Homepage from './Components/Homepage/Homepage';
import './Components/CSS/Main.css'
import './Components/CSS/Mobile.css'

function App() {

  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Homepage />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;