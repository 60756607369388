import Modal from '../Modal/Modal'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    FacebookShareCount,
    GabIcon,
    GabShareButton,
    HatenaIcon,
    HatenaShareButton,
    HatenaShareCount,
    InstapaperIcon,
    InstapaperShareButton,
    LineIcon,
    LineShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    LivejournalIcon,
    LivejournalShareButton,
    MailruIcon,
    MailruShareButton,
    OKIcon,
    OKShareButton,
    OKShareCount,
    PinterestIcon,
    PinterestShareButton,
    PinterestShareCount,
    PocketIcon,
    PocketShareButton,
    RedditIcon,
    RedditShareButton,
    RedditShareCount,
    TelegramIcon,
    TelegramShareButton,
    TumblrIcon,
    TumblrShareButton,
    TumblrShareCount,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    VKIcon,
    VKShareButton,
    VKShareCount,
    WeiboIcon,
    WeiboShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    WorkplaceIcon,
    WorkplaceShareButton,
    XIcon,
  } from "react-share";


const ShareModal = ({openShareModal, setOpenShareModal}) => {

    var todayDate = moment().format('YYYY-MM-DD');

    const shareUrl = "https://momentom.ai?date=" + todayDate;
    const title = "Moment of Mindfulness";

    return (
        <div>
              <Modal open={openShareModal}>
                    <div className="d-flex justify-content-center">
                        <h4 style={{position: 'absolute', right: '1em'}}>
                            <button className="close" onClick={() => setOpenShareModal(false)}><FontAwesomeIcon icon={faCircleXmark} /></button>
                        </h4>
                    </div>

                    <div className="modal text-gray-800 leading-7">
                        <div id="modalHeader" className="ModalHeader">
                            <h2>Share</h2>
                        </div>
                        <hr/>
                        <div id="aboutContent" className="ModalContent">
                            <div className="ShareIconContainer" >
                                <FacebookShareButton
                                    url={shareUrl} >
                                    <FacebookIcon size={32} />
                                </FacebookShareButton>
                                <FacebookMessengerShareButton
                                    url={shareUrl} 
                                    >
                                    <FacebookMessengerIcon size={32} />
                                </FacebookMessengerShareButton>
                                <TwitterShareButton
                                    url={shareUrl} >
                                    <TwitterIcon size={32} />
                                </TwitterShareButton>
                                <WhatsappShareButton
                                    url={shareUrl} >
                                    <WhatsappIcon  size={32}/>
                                </WhatsappShareButton>
                                <LinkedinShareButton
                                    url={shareUrl} >
                                    <LinkedinIcon size={32} />
                                </LinkedinShareButton>
                                <PinterestShareButton
                                    url={shareUrl} >
                                    <PinterestIcon size={32} />
                                </PinterestShareButton>
                            </div>
                        </div>
                    </div>                        
                </Modal>
        </div>
    )
}

export default ShareModal
