import React, { Component } from 'react'
import moment from 'moment';
import Loading from '../../Assets/Loading.gif';
import ValidateDate from '../Validators/DateValidator';

class GetMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {
          items: [],
          DataisLoaded: false,
          error: null
        };
    }

    async componentDidMount() {
      this.loadData();
    }



    async loadData() {

        const urlSearchString = window.location.search;
        const params = new URLSearchParams(urlSearchString);

        const isDateValidated = ValidateDate(params.get('date'));
     
        if (isDateValidated === false) {
            // Date in the URL is not valid so get todays date
            var formattedDate = moment().format('YYYY-MM-DD');
        } else {
            //date in the URL is valid so format it and use it.
            var formattedDate = moment(params.get('date')).format('YYYY-MM-DD');
        }


        var formattedDate = moment().format('YYYY-MM-DD');

        const baseURL = process.env.REACT_APP_API_ENDPOINT + "message";

        fetch(baseURL, {
            method: "POST",
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "date" : formattedDate
            })
          })
  
        .then((response)=> {
            if (!response.ok) {
                this.setState({error: "There was an error retriving todays meditation"});
            } else {
                return response.json();
            }
         })
        .then((json) => {
            
            this.props.UpdateLoadedState(true);
            this.setState({
                items: json,
                DataisLoaded: true,
            });
            
        })

        .catch((error) => {        
            this.setState({error: "There was an error retriving todays meditation"});
            console.log(error)  
        });
    }

    render() {

        if (!this.state.DataisLoaded) {
  
            return (
                <div className='Loading'>
                    <img alt ="Loading Icon" src={Loading} /> 
                </div> 

            )
  
        } else {
            if (this.state.error == null) {
                return (
                    this.state.items.message
                )
            } else {
                return (
                    this.state.error
                )
            }

        }
    
    }

}

export default GetMessage;