import React, { Component } from 'react'
import Contact from '../Contact/Contact'
import About from '../About/About'
import Donations from '../Donations/Donations'
import Share from '../Share/Share'


class FooterClass extends Component {

    render () {
        return (
            <>
                <div className='Padding5'>
                    <About />
                </div>
                <div className='Padding5'>
                    <Contact /> 
                </div>
                <div className='Padding5'>
                    <Donations />
                </div>
                <div className='Padding5'>
                    <Share />
                </div>
            </>
        );
    }
}

export default FooterClass