import React, { useState } from "react"
import ShareModal from "./ShareModal"
import Logo from '../../Assets/ShareIcon.svg'


const Share = () => {
  const [openShareModal, setOpenShareModal] = useState(false);

  return (
    <>
      {" "}
      {openShareModal && openShareModal && (
        <ShareModal
          openShareModal={openShareModal}
          setOpenShareModal={() => setOpenShareModal(false)}
        />
      )}
      <button
        onClick={() => setOpenShareModal(true)}
        className="ImageButton"
        title="Share"
        >
         <img src={Logo} alt="ShareIcon" />
      </button>
    </>
  );
};
export default Share;
