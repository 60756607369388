import React, { useState } from "react"
import ContactModal from "./ContactModal"
import EmailIcon from '../../Assets/EmailIcon32px.svg'

const Contact = () => {
  const [openContactModal, setopenContactModal] = useState(false);

  return (
    <>
      {" "}
      {openContactModal && openContactModal && (
        <ContactModal
          openContactModal={openContactModal}
          setopenContactModal={() => setopenContactModal(false)}
        />
      )}
      <button
        onClick={() => setopenContactModal(true)}
        className="ImageButton"
        title="Contact" >
          <img src={EmailIcon} alt="Logo"/>      
      </button>
    </>
  );
};
export default Contact;
