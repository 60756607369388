import React from 'react'
import Modal from '../Modal/Modal'
import ContactForm from './Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'

const ContactModal = ({openContactModal, setopenContactModal}) => {
    return (
        <div>
              <Modal open={openContactModal}>
                    <div className="d-flex justify-content-center">
                        <h4 style={{position: 'absolute', right: '2em'}}>
                            <button className="close" onClick={() => setopenContactModal(false)}><FontAwesomeIcon icon={faCircleXmark} /></button>
                        </h4>
                    </div>

                    <div className="modal text-gray-800 leading-7">
                        <div id="modalContactHeader" className="ModalHeader">
                            <h2>Contact</h2>
                            <hr/>
                        </div>
                        <div id="modalContent" className='ModalContent'>
                            Feel free to drop me a message with any suggestions to make this app better or with any feedback or questions.<br/>
                        </div>
                        <div>
                            <ContactForm/>
                        </div>
                    </div>                     
                </Modal>
        </div>
    )
}

export default ContactModal