import React, { useState } from "react";
import validator from "validator";

const ValidateDate = (x) => {


    if (validator.isDate(x)) {
        //Date is valid so return true
        var dateValid=true
      } else {
        //Date is invalid so return false
        var dateValid=false
      }

    return dateValid;

}

export default ValidateDate;